<template>
  <ModernModalLayout
    :title="title"
    close-label="Отмена"
    size="xlarge"
    :watch-for="form"
  >
    <v-form v-if="form" ref="form" :disabled="$wait('submit')">
      <v-row>
        <v-col cols="3">
          <DatePicker
            v-if="computedType === limitType.BY_TOTAL"
            v-model="form.settings.dateFrom"
            label="С какой даты"
            dense
            outlined
            hide-details="auto"
            :error-messages="getValidationErrors('form.settings.dateFrom')"
            @blur="validateField('form.settings.dateFrom')"
            @input="debounceGetLimitCounter"
          />
          <DatePicker
            v-if="computedType === limitType.BY_DATE"
            v-model="form.settings.dateEnd"
            label="По какую дату"
            dense
            outlined
            hide-details="auto"
            :min="minDate"
            :error-messages="getValidationErrors('form.settings.dateEnd')"
            @blur="validateField('form.settings.dateEnd')"
          />
          <Select
            v-if="computedType === limitType.BY_PERIOD"
            v-model="form.settings.period"
            label="За какой период"
            :items="limitPeriods"
            dense
            hide-details="auto"
            @change="debounceGetLimitCounter"
          />
        </v-col>

        <v-col cols="9" class="d-flex">
          <template v-if="computedType !== limitType.BY_DATE">
            <v-text-field
              :value="counter"
              style="width: 200px; flex-shrink: 0"
              label="Израсходовано осмотров"
              outlined
              disabled
              dense
              hide-details="auto"
              :loading="$wait('getLimitCounter')"
              persistent-placeholder
              hint="Получено осмотров в систему по Московскому времени"
              persistent-hint
            />
            <span class="ma-2"> / </span>
            <MaskField
              v-model="form.settings.limit"
              label="Счетчик осмотров"
              :hint="form.settings.limit > 0 ? limitHintCalc : ''"
              :mask="maskPosNumber"
              style="width: 200px; flex-shrink: 0"
              persistent-hint
              outlined
              dense
              hide-details="auto"
              :error="limitExpenditurePercent >= 100"
              :error-messages="getValidationErrors('form.settings.limit')"
              @change="debounceGetLimitCounter"
              @blur="validateField('form.settings.limit')"
            />
          </template>
        </v-col>
      </v-row>

      <div>
        <div class="subtitle-1 mt-5 mb-5">Уведомления</div>
        <LimitFormNotifications
          ref="limitFormNotif"
          :value="form.settings.notifications"
          :type="type"
          @change="changeNotifications"
        />
      </div>
    </v-form>

    <template #actions:append>
      <v-btn
        class="px-5"
        depressed
        color="primary"
        :disabled="$wait('submit')"
        @click="submit"
      >
        {{ isUpdate ? 'Сохранить' : 'Добавить' }}
      </v-btn>
    </template>
  </ModernModalLayout>
</template>

<script>
import { minValue, required } from 'vuelidate/lib/validators';
import { mapActions } from 'vuex';
import _cloneDeep from 'lodash/cloneDeep';
import _debounce from 'lodash/debounce';
import validation from '@/utils/validation';
import waitable from '@/utils/mixins/waitable';
import { maskPosNumber } from '@/utils/masks';

import ModernModalLayout from '@/components/layouts/ModernModalLayout';
import DatePicker from '@/components/controls/DatePicker.vue';
import Select from '@/components/controls/Select.vue';
import LimitFormNotifications from '@/components/limits/LimitFormNotifications';
import MaskField from '@/components/controls/MaskField';

import {
  LimitPeriod,
  LimitType,
  LimitTypeName,
  LimitPeriodName,
} from '@/api/services/limits.interfaces';
import { currentDate, numberWithSpaces } from '@/utils/helpers';

export default {
  components: {
    ModernModalLayout,
    DatePicker,
    Select,
    MaskField,
    LimitFormNotifications,
  },
  mixins: [validation, waitable],
  props: {
    groupp: { type: Boolean, default: false },
    type: { type: String, default: null },
    orgOrGroupId: { type: Number, default: null },
    value: { type: Object, default: () => null },
  },

  validations() {
    const form = {
      settings: {
        ...(this.type === LimitType.BY_TOTAL && {
          dateFrom: { required },
          limit: { required, minValue: minValue(1) },
        }),
        ...(this.type === LimitType.BY_PERIOD && {
          period: { required },
          limit: { required, minValue: minValue(1) },
        }),
        ...(this.type === LimitType.BY_DATE && {
          dateEnd: { required },
        }),
      },
    };

    return { form };
  },

  data: () => ({ form: null, countNow: null }),

  computed: {
    limitType: () => LimitType,
    limitPeriods: () =>
      Object.entries(LimitPeriodName).map(([k, v]) => ({ id: k, name: v })),

    isUpdate() {
      return !!this.value?.id;
    },

    computedType() {
      return this.type || this.value?.type;
    },

    maskPosNumber: () => maskPosNumber,

    title() {
      const action = this.isUpdate
        ? 'Редактирование лимита'
        : 'Добавление лимита';
      return `${action} "${LimitTypeName[this.computedType]}"`;
    },

    limitExpenditurePercent() {
      const {
        settings: { limit = 0 },
      } = this.form || {};
      return this.calcPercent(this.countNow, limit);
    },

    limitHintCalc() {
      if (
        Number.isNaN(this.limitExpenditurePercent) ||
        this.limitExpenditurePercent === null
      )
        return `Ошибка в расчетах, проверьте значения!`;
      if (this.limitExpenditurePercent >= 100) return `Лимит исчерпан!`;
      return `Израсходовано ${this.limitExpenditurePercent}% лимита`;
    },

    counter() {
      const value = this.countNow ?? '–';
      if (Number.isNaN(value)) return '–';
      return numberWithSpaces(value);
    },

    minDate() {
      return currentDate(true).plus({ days: 1 }).toFormat('yyyy-MM-dd');
    },
  },

  created() {
    // Формируем поля формы на этапе создания экземпляра компонента
    if (this.value) this.form = _cloneDeep(this.value);
    else this.generateForm();
  },

  mounted() {
    if (this.type !== LimitType.BY_DATE) this.getLimitCounter();
  },

  methods: {
    ...mapActions('STRUCTURES/LIMITS', [
      'create',
      'update',
      'limitCount',
      'fetchReceiverPreviews',
    ]),

    getStartPeriod(period) {
      return currentDate(true)
        .setZone(180)
        .startOf(period)
        .toFormat('yyyy-MM-dd');
    },

    async getLimitCounter() {
      const params = {
        ...(this.type === LimitType.BY_TOTAL && {
          dateStart: this.form.settings.dateFrom,
        }),
        ...(this.type === LimitType.BY_DATE && {
          dateEnd: this.form.settings.dateEnd,
        }),
        ...(this.type === LimitType.BY_PERIOD && {
          dateStart: this.getStartPeriod(this.form.settings.period),
        }),
      };

      await this.$loadingNotify(
        this.limitCount({
          orgOrGroupId: this.orgOrGroupId,
          groupp: this.groupp,
          ...params,
        }).then(({ total }) => (this.countNow = total)),
        'getLimitCounter',
        'Не удалось загрузить количество пройденных осмотров на текущий момент',
      );
    },

    debounceGetLimitCounter: _debounce.call(
      this,
      function () {
        // REVIEW: https://jira.medpoint24.ru/browse/SW-9523
        // if things will work ok then remove this commented code
        // this.validateField('form.settings.limit');
        // if (this.getValidationErrors('form.settings.limit').length) return;
        this.getLimitCounter();
      },
      500,
    ),

    generateForm() {
      if (!this.type || !this.orgOrGroupId) {
        console.error(
          'Не заданы тип или организация/группа организаций создаваемого лимита',
        );
        return;
      }

      // Заготовка на все варианты форм
      const form = {
        type: this.type,
        orgOrGroupId: this.orgOrGroupId,
        settings: {
          notifications: [],
        },
        isGroupLimit: this.groupp,
      };

      if (this.type === LimitType.BY_TOTAL)
        form.settings.dateFrom = currentDate();
      else if (this.type === LimitType.BY_PERIOD)
        form.settings.period = LimitPeriod.MONTH;
      else if (this.type === LimitType.BY_DATE) {
        form.settings.dateEnd = currentDate(true)
          .plus({ days: 1 })
          .toFormat('yyyy-MM-dd');
      }

      this.form = form;
    },

    calcPercent(from = 1, to) {
      if (Number(to) === 0) return 0;
      const value = Math.floor((from / to) * 100);
      if (Number.isNaN(value)) return null;
      return value;
    },

    async submit() {
      const limitFormValidation = this.$refs.limitFormNotif.validate();
      if (!this.validate() || !limitFormValidation) {
        this.$notify({
          group: 'note',
          type: 'error',
          title: 'Ошибка заполнения формы',
          text: 'При заполнении формы допущены ошибки, исправьте их и повторите действие снова',
        });
        return;
      }
      const form = _cloneDeep(this.form);

      form.settings.notifications.forEach(notification => {
        notification.threshold &&
          (notification.threshold = Number(notification.threshold));
        notification.periodSize &&
          (notification.periodSize = Number(notification.periodSize));
      });

      const actionName = this.isUpdate
        ? () => this.update({ id: form.id, payload: form })
        : () => this.create(form);

      await this.$loadingNotify(
        actionName(),
        'submit',
        `Произошла ошибка ${this.isUpdate ? 'изменения' : 'создания'} лимита`,
        `Лимит успешно ${this.isUpdate ? 'изменен' : 'создан'}`,
      )
        .then(() => {
          this.onSubmit && this.onSubmit();
          this.fetchReceiverPreviews();
          this.$emit('close');
        })
        .catch(err => this.catchServerErrors(err, 's'));
    },

    changeNotifications(event) {
      this.$set(this.form.settings, 'notifications', event);
    },
  },
};
</script>
