<template>
  <Fragment>
    <v-row v-for="(n, index) in list" :key="n.rowId">
      <v-col cols="12" class="pb-0">
        <v-checkbox
          v-if="type === LIMIT_TYPE.BY_DATE"
          v-model="n.byFact"
          class="mt-0"
          label="По факту срабатывания лимита"
          hide-details="auto"
          @change="byFactChanged({ id: n.rowId, value: $event })"
        />
      </v-col>
      <v-col cols="10" class="d-flex">
        <template v-if="type === LIMIT_TYPE.BY_DATE">
          <Select
            v-model="n.period"
            label="Период"
            :items="limitPeriods"
            class="mr-2"
            dense
            hide-details="auto"
            :disabled="n.byFact"
          />
          <MaskField
            v-model="n.periodSize"
            style="width: 150px; flex-shrink: 0"
            :mask="maskPosNumber"
            class="mr-2"
            outlined
            dense
            label="Значение"
            hide-details="auto"
            :disabled="n.byFact"
            :error-messages="
              getValidationErrors('value.$each.' + index + '.periodSize')
            "
            @blur="validateField('value.$each.' + index + '.periodSize')"
          />
        </template>
        <MaskField
          v-else
          v-model="n.threshold"
          style="width: 150px; flex-shrink: 0"
          class="mr-2"
          :mask="maskPosNumber"
          outlined
          dense
          label="Процент"
          hide-details="auto"
          :error-messages="
            getValidationErrors('value.$each.' + index + '.threshold')
          "
          @blur="validateField('value.$each.' + index + '.threshold')"
        />

        <AccountSelect
          v-model="n.receivers"
          multiple
          dense
          title="Получатели"
          hide-details="auto"
          :error-messages="
            getValidationErrors('value.$each.' + index + '.receivers')
          "
          @blur="validateField('value.$each.' + index + '.receivers')"
        />
      </v-col>

      <v-col cols="2" class="d-flex align-center justify-space-between">
        <v-btn outlined dense @click="copyNotification(index)">
          <v-icon size="18">fa-copy</v-icon>
        </v-btn>
        <v-btn outlined dense color="error" @click="removeNotification(index)">
          <v-icon size="18">fa-trash</v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <div cols="12" class="d-flex justify-center mt-3">
      <v-btn color="primary" outlined @click="addNotification">
        <v-icon size="14" class="fa-fw mr-2">fa-plus</v-icon>
        Добавить строку
      </v-btn>
      <v-btn
        :disabled="hasNotificationForSelf"
        color="primary"
        class="ml-3"
        outlined
        @click="() => addNotification(true)"
      >
        <v-icon size="14" class="fa-fw mr-2">fa-plus</v-icon>
        Добавить уведомление себе
      </v-btn>
    </div>
  </Fragment>
</template>

<script>
import {
  required,
  requiredIf,
  minValue,
  maxValue,
} from 'vuelidate/lib/validators';
import { mapGetters } from 'vuex';
import validationMixin from '@/utils/validation';
import _cloneDeep from 'lodash/cloneDeep';
import { maskPosNumber } from '@/utils/masks';
import {
  LimitPeriod,
  LimitPeriodName,
  LimitType,
} from '@/api/services/limits.interfaces';

import Select from '@/components/controls/Select.vue';
import MaskField from '@/components/controls/MaskField';
import AccountSelect from '@/components/controls/structures/AccountSelect';

export default {
  components: { Select, MaskField, AccountSelect },

  mixins: [validationMixin],

  validations() {
    return {
      value: {
        $each: {
          periodSize: {
            required: requiredIf(() => this.type === this.LIMIT_TYPE?.BY_DATE),
          },
          threshold: {
            required: requiredIf(() => this.type !== this.LIMIT_TYPE?.BY_DATE),
            minValue: minValue(1),
            maxValue: maxValue(100),
          },
          receivers: { required },
        },
      },
    };
  },
  props: {
    value: { required: true, type: Array },
    type: { required: true, type: String },
  },

  data: () => ({
    list: null,
    // variable to not duplicate row ids, gets +1 every time new row is created
    rowIdsCounter: null,
  }),

  computed: {
    ...mapGetters('AUTH', ['accountId']),
    LIMIT_TYPE: () => LimitType,
    limitPeriods: () =>
      Object.entries(LimitPeriodName).map(([k, v]) => ({ id: k, name: v })),

    maskPosNumber: () => maskPosNumber,

    hasNotificationForSelf() {
      return (
        this.list &&
        this.list
          .map(row => row.receivers)
          .flat()
          .includes(this.accountId)
      );
    },
  },

  watch: {
    list: {
      deep: true,
      handler() {
        this.$nextTick(this.onChange);
      },
    },
  },

  created() {
    this.list = this.value.length
      ? _cloneDeep(this.value)
      : [this.getRowFields()];

    this.list.map(row => {
      // assign unique id to every row passed from parent component
      !row.rowId && (row.rowId = this.assignId());

      // Уведомления по факту определяются размером периода "0", но отображается чекбоксом
      row.byFact = row.periodSize === 0;
      if (row.byFact) {
        row.periodSize = null;
        row.period = null;
      }
    });
  },

  methods: {
    // self means 'add notification for myself'
    getRowFields(self = false) {
      return {
        rowId: this.assignId(),
        receivers: self ? [this.accountId] : [],
        ...(this.type === LimitType.BY_DATE
          ? { period: LimitPeriod.MONTH, periodSize: 1 }
          : { threshold: self ? 80 : null }),
      };
    },

    assignId() {
      this.rowIdsCounter = (this.rowIdsCounter || 0) + 1;
      return this.rowIdsCounter;
    },

    onChange() {
      this.$emit(
        'change',
        this.list.map(row => {
          const rowCopy = { ...row };
          // clean frontend leftovers, othervise backend will save this field
          delete rowCopy.rowId;

          // Если установлено уведомление по факту, на сервер нужно передать "0 дней"
          if (rowCopy.byFact) {
            rowCopy.periodSize = '0';
            rowCopy.period = LimitPeriod.DAY;
          }
          delete rowCopy.byFact;
          return rowCopy;
        }),
      );
    },

    addNotification(self = false) {
      // NOTE: we always get 'self' as argument, but it might be just mouse
      // event obj so we need to check for its type and value explicitly
      const addSelf = self === true;

      // getRowFields does nothing but gets a row, it should not determine
      // where to slice or when to unshift, so we do those checks below
      const row = this.getRowFields(addSelf);

      // of 1st notification has no receivers and user want to add himself -
      // replace first array element
      if (!this.list[0]?.receivers.length && addSelf) {
        // copy byFact value in case user changed it
        row.byFact = this.list[0].byFact;
        if (row.byFact) {
          row.periodSize = undefined;
          row.period = undefined;
        }
        this.list.splice(0, 1);
        this.list.unshift(row);
      } else this.list.push(row);
    },

    copyNotification(index) {
      const el = _cloneDeep(this.list[index]);
      this.list.push(el);
    },

    removeNotification(index) {
      this.list.splice(index, 1);

      // Добавить пустую строку при удалении последней
      if (!this.list.length && !index) this.addNotification();
    },

    byFactChanged({ id, value }) {
      const targetRow = this.list.find(row => row.rowId === id);
      if (value) {
        targetRow.periodSize = undefined;
        targetRow.period = undefined;
      } else {
        targetRow.periodSize = '1';
        targetRow.period = LimitPeriod.MONTH;
      }
    },
  },
};
</script>
